import {
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  Menu,
  RadioGroup,
  styled,
  SwipeableDrawer,
} from '@mui/material';
import { Caption, EhiButton, ehiTheme } from '@ehi/ui';
import CSS from 'csstype';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { FormTextField } from 'components/shared/forms/FormTextField';
import Select from '@mui/material/Select';

export const StyledDialog = styled(Dialog)({
  '.MuiDialogContent-root': {
    overflow: 'hidden',
  },
});

export const StyledFilterButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: ehiTheme.spacing(0, 1),
  '&&': {
    padding: theme.spacing(0, 1, 0, 0),
  },
}));
export const EhiButtonReset = styled(EhiButton)(() => ({
  width: '40px',
  height: '36px',
  fontsize: '14px',
}));

export const buttonImages: any = {
  mapIcon: MapOutlinedIcon,
  listIcon: ViewStreamOutlinedIcon,
};

export const StyledIconButton = styled(IconButton)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(1, 0, 1, 0),
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(1, 1, 0, 0),
  color: '#000000',
}));

export const SubHeaderContainer = styled('div')`
  position: sticky;
  z-index: 1000;
  background-color: #ffffff;
`;

export const CountryDropDownHeader = styled(SubHeaderContainer)`
  color: #000000de;
  top: 0;
`;

export const CountryDropDownFooter = styled(SubHeaderContainer)`
  bottom: 0;
`;

export const CountryDropDownHeaderItem = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
`;

export const CountryDropDownCaption = styled(Caption)`
  padding: ${ehiTheme.spacing(0, 2)};
`;

export const CountryDropDownFooterItem = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const countrySelect: CSS.Properties = {
  width: '100%',
  height: '40px',
  padding: ehiTheme.spacing(0),
};

export const OutlinedIconSelected: CSS.Properties = {
  width: '24px',
  height: '24px',
  padding: ehiTheme.spacing(1),
  gap: '0px',
  opacity: '0px',
  backgroundColor: '#4D789D',
  color: '#FFFFFF',
};

export const OutlinedIconNonSelected: CSS.Properties = {
  width: '24px',
  height: '24px',
  padding: ehiTheme.spacing(1),
  gap: '0px',
  opacity: '0px',
  backgroundColor: '#FFFFFF',
  color: '#4D789D',
};

export const OutlinedDisabledIconWhite: CSS.Properties = {
  width: '24px',
  height: '24px',
  padding: ehiTheme.spacing(1, 1, 0, 1),
  gap: '0px',
  opacity: '0px',
  backgroundColor: '#FFFFFF',
};

export const OutlinedDisabledIconGray: CSS.Properties = {
  width: '24px',
  height: '24px',
  padding: ehiTheme.spacing(1),
  gap: '0px',
  opacity: '0px',
  backgroundColor: '#0000001F',
};

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    min-width: 20rem;
    max-width: 21.875rem;
  }
`;

export const StyledSelect = styled(Select)(() => ({
  borderRadius: '33px',
  height: '40px',
}));

export const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const AllToggleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: ${ehiTheme.spacing(0.5, 0)};
  border-top: 1px solid #ccc;
`;

export const StyledText = styled('div')`
  font-size: 1rem;
  font-weight: bold;
  color: grey;
`;

export const StyledInputLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<{
  bgColor: string;
}>((props) => ({
  '&.Mui-focused': {
    color: 'inherit',
  },
  '&.MuiInputLabel-shrink': {
    transform: 'translate(0.875rem, -0.5rem) scale(0.75)',
    background: props.bgColor,
    padding: '0 0.25rem',
    zIndex: 1,
  },
}));

export const StyledBranchListBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'containerHeight',
})<{ containerHeight: number }>((props) => ({
  backgroundColor: '#f5f5f5',
  height: props.containerHeight,
  position: 'relative',
}));

export const StyledVirtuosoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMapView' && prop !== 'isMobileOrTablet' && prop !== 'disableBottomDrawer',
})<{
  isMapView: boolean;
  isMobileOrTablet: boolean;
  disableBottomDrawer: boolean;
}>((props) => ({
  width: (props.isMapView && props.disableBottomDrawer) || props.isMobileOrTablet ? '94%' : '60%',
}));

export const StyledHeaderText = styled(Caption, {
  shouldForwardProp: (prop) => prop !== 'isMapView' && prop !== 'isMobileOrTablet' && prop !== 'disableBottomDrawer',
})<{
  isMapView: boolean;
  isMobileOrTablet: boolean;
  disableBottomDrawer: boolean;
}>(({ isMapView, isMobileOrTablet, disableBottomDrawer }) => {
  let widthPercent = '60%';

  if (isMapView) {
    widthPercent = disableBottomDrawer ? '94%' : '100%';
  } else if (isMobileOrTablet) {
    widthPercent = '94%';
  }

  return {
    margin: ehiTheme.spacing(3, 0, 0.5),
    width: widthPercent,
  };
});

export const VirtuosoContentContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledButtonContainer = styled('div')`
  display: flex;
  margin: ${ehiTheme.spacing(-1, -1, 0, -2)};
`;

export const StyledBranchLookUpSearchBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledFormTextField = styled(FormTextField)`
  width: 295px;
  @media (max-width: 800px) {
    width: 240px;
  }
`;

export const StyledFormControl = styled(FormControl)`
  width: 295px;

  @media (max-width: 800px) {
    width: 240px;
  }
`;

export const StyledBoxDropDownContainer = styled(Box)`
  width: 60%;
  display: flex;
  padding-top: ${ehiTheme.spacing(2)};
  gap: ${ehiTheme.spacing(2)};
  justify-content: space-between;

  @media (max-width: 800px) {
    width: 94%;
  }
`;

export const StyledSearchInput = styled(FormTextField)`
  width: 60%;
  @media (max-width: 800px) {
    width: 94%;
  }
`;

export const StyledFilterBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledFilterContainer = styled(Box)`
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 800px) {
    width: 94%;
  }
`;

export const StyledDistanceTextField = styled(FormTextField)`
  width: 160px;
  @media (max-width: 800px) {
    width: 120px;
  }
`;

export const StyledLocationsFormControl = styled(FormControl)`
  width: 190px;
  padding-right: ${ehiTheme.spacing(1)};
`;

export const StyledAutoCompleteField = styled(Autocomplete)`
  width: 165px;
  @media (max-width: 800px) {
    width: 145px;
  }
`;

export const EmptyPlaceHolder = styled('div')`
  width: 160px;
  @media (max-width: 800px) {
    width: 120px;
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  width: 60%;

  @media (max-width: 800px) {
    width: 94%;
  }
`;

export const DrawerButtonUp = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  cursor: 'pointer',
  position: 'absolute',
  color: 'grey',
  backgroundColor: '#FFFFFF',
  textTransform: 'none',
  bottom: 0,
  height: 70,
  zIndex: 1150,
  margin: 0,
}));

export const DrawerButtonDown = styled(Button)(({ theme }) => ({
  width: '100%',
  height: 70,
  cursor: 'pointer',
  backgroundColor: '#FFFFFF',
  zIndex: 1150,
  '&:hover': {
    backgroundColor: '#F5F7FC',
  },
  [theme.breakpoints.up('sm')]: {
    margin: 0,
  },
}));

export const StyledDrawerList = styled(SwipeableDrawer)(() => ({
  '.MuiDrawer-paperAnchorBottom': {
    position: 'absolute',
  },
}));

export const StyledScrollList = styled('div')(() => ({
  height: '50vh',
}));

export const drawerButtonStyles = {
  height: '55px',
  margin: '12px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const DrawerListView = styled('div')(() => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  zIndex: 1150,
  backgroundColor: '#F5F5F5',
  '&.mapView': {
    width: '45%',
    padding: ehiTheme.spacing(0, 1),
  },
}));

export const DrawerButton = styled('div')(({ theme }) => ({
  width: 80,
  height: 4,
  backgroundColor: '#E0E0E0',
  borderRadius: 2,
  [theme.breakpoints.only('xs')]: {
    width: 24,
  },
}));

export const StyledMap = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  flex: 1,
  backgroundColor: '#FFFFFF',
  [ehiTheme.breakpoints.up('md')]: {
    order: 2,
    flex: 2,
  },
  [ehiTheme.breakpoints.down('sm')]: {
    order: 1,
    padding: 0,
  },
}));
