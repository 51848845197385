import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslations } from 'components/shared/i18n';
import {
  AllToggleContainer,
  ButtonContainer,
  EhiButtonReset,
  HeaderContainer,
  StyledFormTextField,
  StyledText,
  SubHeaderContainer,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { Body1, ehiTheme } from '@ehi/ui';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import ListItemText from '@mui/material/ListItemText';
import { AccountSearchFields } from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/AccountSearchTypes';
import {
  accountTypeOptionType,
  getAccountTypeOptions,
} from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/accountSearchUtils';
import { ListItemIcon } from '@mui/material';
import { Check } from '@mui/icons-material';

export interface AccountTypesDropDownProps {
  accountTypeValues: string[];
}

export const AccountTypesDropDown: FC<AccountTypesDropDownProps> = ({ accountTypeValues }) => {
  const { t } = useTranslations();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setIsOpen] = useState<boolean>(false);
  const { watch, setValue, resetField } = useFormContext();
  const dropDownOptions = getAccountTypeOptions(t);
  const selectedTypes = watch(AccountSearchFields.AccountTypes);

  const allTypesSelected = selectedTypes?.length === accountTypeValues?.length;

  const handleOpen = (event: SyntheticEvent<Element, Event>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    setIsOpen(true);
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleAllTypes = useCallback(() => {
    const newSelectedTypes = allTypesSelected ? [] : accountTypeValues;
    setValue(AccountSearchFields.AccountTypes, newSelectedTypes);
  }, [allTypesSelected, accountTypeValues, setValue]);

  const handleReset = useCallback(() => {
    resetField(AccountSearchFields.AccountTypes);
  }, [resetField]);

  const renderChips = useMemo(() => {
    const label = dropDownOptions.find((item) => item.code === selectedTypes[0])?.name;
    return `${label?.substring(0, 15)}, +${selectedTypes?.length - 1}`;
  }, [selectedTypes, dropDownOptions]);

  const renderSelectedTypes = useCallback(() => {
    if (allTypesSelected) {
      return t('common.all');
    } else if (selectedTypes?.length === 1) {
      return selectedTypes[0];
    } else if (!selectedTypes?.length) {
      return t('rateAndBilling.accountTypes');
    } else {
      return renderChips;
    }
  }, [t, selectedTypes, allTypesSelected, renderChips]);

  return (
    <StyledFormTextField
      name={AccountSearchFields.AccountTypes}
      data-testid={AccountSearchFields.AccountTypes}
      label={t('rateAndBilling.accountTypeLabel')}
      required
      select
      sx={{
        '&& .Mui-focused': {
          color: '#000000',
        },
      }}
      SelectProps={{
        open: open,
        displayEmpty: true,
        multiple: true,
        value: selectedTypes,
        renderValue: renderSelectedTypes,
        MenuProps: {
          anchorEl: anchorEl,
          autoFocus: false,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '&& .Mui-selected': { backgroundColor: '#ffffff' },
            transition: 'none !important',
          },
          slotProps: {
            paper: {
              style: {
                width: anchorEl ? `${anchorEl.clientWidth * 1.25}px` : 'auto',
                maxHeight: window.innerHeight / 1.75,
                ...(window.innerWidth <= 800 && {
                  minWidth: '25%',
                }),
              },
            },
            root: { sx: { '.MuiList-root': { padding: 0 } } },
          },
        },
        onClose: handleClose,
        onOpen: handleOpen,
      }}>
      <SubHeaderContainer style={{ top: 0 }}>
        <MenuItem>
          <HeaderContainer>
            <Body1 bold>{t('rateAndBilling.accountTypeLabel')}</Body1>
            <IconButton size='small' onClick={handleClose}>
              <CloseIcon color={'secondary'} data-testid='close-dropdown' />
            </IconButton>
          </HeaderContainer>
        </MenuItem>
        <MenuItem>
          <AllToggleContainer>
            <Body1>{t('rateAndBilling.allAccountTypes')}</Body1>
            <Switch checked={allTypesSelected} onChange={toggleAllTypes} data-testid='allTypesSwitch' />
          </AllToggleContainer>
        </MenuItem>
        <MenuItem>
          <AllToggleContainer>
            <StyledText>{t('rateAndBilling.accountTypes')}</StyledText>
          </AllToggleContainer>
        </MenuItem>
      </SubHeaderContainer>
      {dropDownOptions?.map((item: any) => (
        <MenuItem
          key={item.code}
          data-testid={`account-type-${item.code}`}
          value={item.code}
          style={{ padding: ehiTheme.spacing(2) }}>
          <ListItemText primary={item.name} />
          {selectedTypes?.some((selected: accountTypeOptionType) => selected === item.code) && (
            <ListItemIcon data-testid={`checked-${item.code}`}>
              <Check color={'action'} />
            </ListItemIcon>
          )}
        </MenuItem>
      ))}
      <SubHeaderContainer style={{ bottom: 0 }}>
        <ButtonContainer>
          <EhiButtonReset onClick={handleReset} disabled={allTypesSelected}>
            {t('common.reset')}
          </EhiButtonReset>
        </ButtonContainer>
      </SubHeaderContainer>
    </StyledFormTextField>
  );
};
