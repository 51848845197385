import { FC, MutableRefObject, useCallback, useState } from 'react';
import {
  AccountNumberSearchContainer,
  RateSourceField,
  RateSourceSearchContainer,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Box, Grid } from '@mui/material';
import { Body1, EhiButton, ehiTheme, H6 } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { RateSourceFields } from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/RateSourceDialogTypes';
import { FieldLoadingIndicator } from 'components/shared/forms/FieldLoadingIndicator';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { EMPTY_VALUE } from 'utils/constants';
import { useTranslations } from 'components/shared/i18n';
import { useFormContext, useWatch } from 'react-hook-form';
import { transformRateSourceInfoFromApi } from 'components/shared/uiModels/rateSource/rateSourceTransformer';
import { RateSource } from 'services/booking/bookingTypes';
import { modifyRateSource } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectBrand } from 'redux/selectors/bookingEditor';
import { useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { useAlert } from 'components/shared/alert/AlertContext';
import { BusinessAccount } from 'services/businessAccount/businessAccountTypes';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { QueryObserverResult } from '@tanstack/react-query';
import { logError } from 'components/shared/logger/splunkLogger';
import { EHI_DOMAINS, generateUrn, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';

type AccountNumberSearchContainerProps = {
  submitForm: () => void;
  handleCloseModal: () => void;
  formRef?: MutableRefObject<{ handleSubmit: () => Promise<void> } | null>;
};

export const AccountNumberSearch: FC<AccountNumberSearchContainerProps> = ({
  submitForm,
  handleCloseModal,
  formRef,
}): JSX.Element => {
  const { t } = useTranslations();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const brand = useAppSelector(selectBrand);
  const [loadingAccountNumber, setLoadingAccountNumber] = useState(false);
  const [loadingRateProduct, setLoadingRateProduct] = useState(false);

  const { setValue, getValues, setError, clearErrors, handleSubmit } = useFormContext();
  const [accountNumber, rateProduct] = useWatch({
    name: [RateSourceFields.AccountNumber, RateSourceFields.RateProduct],
  });

  const { refetch: refetchAccountDetails } = useBusinessAccountQuery(accountNumber, {
    enabled: false,
  });

  const handleLogError = useCallback((error: unknown, message: string) => {
    logError({ error, message: message });
  }, []);

  const handleApplyingAccountNumber = useCallback(async () => {
    setLoadingAccountNumber(true);
    await refetchAccountDetails()
      .then(async (response: QueryObserverResult<BusinessAccount, EhiErrors>) => {
        const { data: accountDetails, error: accountErrors } = response;
        if (!accountDetails || accountErrors) {
          setError(RateSourceFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
          return;
        }

        const businessAccount = transformRateSourceInfoFromApi(accountDetails);
        if (businessAccount.urn) {
          const rateSourceRequestBody: RateSource = {
            type: 'NEGOTIATED',
            account: businessAccount.urn ?? undefined,
          };
          const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
          if (!errors) {
            handleCloseModal();
          } else {
            await showAlert({
              variant: 'error',
              description: errors?.[0]?.localizedMessage || '',
            });
          }
        } else {
          setError(RateSourceFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
        }
      })
      .catch((error) => {
        handleLogError(
          error,
          `Unable to save account number ${getValues(RateSourceFields.AccountNumber)} to editor id ${bookingEditorId}`
        );
      })
      .finally(() => {
        setLoadingAccountNumber(false);
      });
  }, [
    bookingEditorId,
    getValues,
    handleCloseModal,
    handleLogError,
    refetchAccountDetails,
    setError,
    showAlert,
    t,
    updateAndRefresh,
  ]);

  const handleApplyingRateProduct = useCallback(async () => {
    try {
      setLoadingRateProduct(true);
      const rateSourceRequestBody: RateSource = {
        type: 'RETAIL',
        rateProduct: generateUrn(
          EHI_DOMAINS.rentalRate.name,
          `${EHI_DOMAINS.rentalRate.brand}:${parseUrn(brand)}:${EHI_DOMAINS.rentalRate.rateProduct}`,
          getValues(RateSourceFields.RateProduct).toUpperCase(),
          defaultEhiDatabase
        ),
      };
      const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
      if (!errors) {
        handleCloseModal();
      } else {
        setError(RateSourceFields.RateProduct, { message: t('rateAndBilling.invalidRateProduct') });
      }
    } catch (error) {
      handleLogError(
        error,
        `Unable to save rate product ${getValues(RateSourceFields.RateProduct)} to editor id ${bookingEditorId}`
      );
    } finally {
      setLoadingRateProduct(false);
    }
  }, [
    bookingEditorId,
    brand,
    defaultEhiDatabase,
    getValues,
    handleCloseModal,
    handleLogError,
    setError,
    t,
    updateAndRefresh,
  ]);

  return (
    <Box>
      <AccountNumberSearchContainer data-testid='accountNumberContainer' paddingBottom={ehiTheme.spacing(2)}>
        <Grid item>
          <Body1>{t('rateAndBilling.enterAccountNumber')}</Body1>
        </Grid>
        <Grid item>
          <RateSourceField data-testid='searchField'>
            <Grid item xs={6} sm={6}>
              <FormTextField
                name={RateSourceFields.AccountNumber}
                label={t('rateAndBilling.accountNumber')}
                data-testid='input'
                fullWidth
                required
                onChange={(e) => setValue(RateSourceFields.AccountNumber, e.target.value)}
                onFocus={() => {
                  if (formRef) {
                    formRef.current = { handleSubmit: handleSubmit(handleApplyingAccountNumber) };
                  }
                }}
                InputProps={{
                  endAdornment: loadingAccountNumber ? (
                    <FieldLoadingIndicator />
                  ) : (
                    <InputIconButton
                      icon={<FieldClearIcon />}
                      label={t('common.clear')}
                      onClick={() => {
                        setValue(RateSourceFields.AccountNumber, EMPTY_VALUE);
                        clearErrors(RateSourceFields.AccountNumber);
                      }}
                      disabled={accountNumber.length === 0}
                    />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <EhiButton
                data-testid='applyButton'
                variant='contained'
                style={{ marginTop: 0 }}
                disabled={!accountNumber || loadingAccountNumber}
                onClick={submitForm}>
                {t('common.apply')}
              </EhiButton>
            </Grid>
          </RateSourceField>
        </Grid>
      </AccountNumberSearchContainer>
      {!accountNumber && (
        <RateSourceSearchContainer data-testid='rateProductContainer' paddingTop={ehiTheme.spacing(2)}>
          <Grid item>
            <H6>{t('rateAndBilling.retail')}</H6>
          </Grid>
          <Grid item>
            <Body1>{t('rateAndBilling.enterRateProduct')}</Body1>
          </Grid>
          <Grid item>
            <RateSourceField
              data-testid='searchField'
              style={{ background: '#f5f5f5', padding: ehiTheme.spacing(2, 2, 1) }}>
              <Grid item xs={6} sm={6}>
                <FormTextField
                  name={RateSourceFields.RateProduct}
                  label={t('rateAndBilling.rateProduct')}
                  data-testid='input'
                  fullWidth
                  onChange={(e) => setValue(RateSourceFields.RateProduct, e.target.value)}
                  onFocus={() => {
                    if (formRef) {
                      formRef.current = { handleSubmit: handleSubmit(handleApplyingRateProduct) };
                    }
                  }}
                  InputProps={{
                    endAdornment: loadingRateProduct ? (
                      <FieldLoadingIndicator />
                    ) : (
                      <InputIconButton
                        icon={<FieldClearIcon />}
                        label={t('common.clear')}
                        onClick={() => {
                          setValue(RateSourceFields.RateProduct, EMPTY_VALUE);
                          clearErrors(RateSourceFields.RateProduct);
                        }}
                        disabled={rateProduct.length === 0}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <EhiButton
                  data-testid='applyButton'
                  variant='contained'
                  style={{ marginTop: 0 }}
                  disabled={!rateProduct || loadingRateProduct}
                  onClick={submitForm}>
                  {t('common.apply')}
                </EhiButton>
              </Grid>
            </RateSourceField>
          </Grid>
        </RateSourceSearchContainer>
      )}
    </Box>
  );
};
