import { useCallback, useState } from 'react';
import { searchBranches } from 'services/location/locationService';
import {
  BranchLookupFields,
  BranchLookupValues,
  SearchByType,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { logError } from 'components/shared/logger/splunkLogger';
import { ErrorSeverity } from '@ehi/analytics';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import {
  BranchDetailsSearchCriteria,
  BranchPhase,
  BranchSearchResults,
  Brand,
  DistanceMeasurementMethod,
  DistanceUnitOfMeasure,
  MatchType,
} from 'services/location/locationTypes';
import { transformBranchLookupData } from 'components/shared/uiModels/branchLookup/branchLookupTransformer';
import { DisplayedLocationTypes, getAirportType, getBranchTypes, getBusinessIndicators } from 'utils/branchLookupUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';

const MAX_PAGE_SIZE = 50;

export type UseBranchSearchReturn = {
  isLoading: boolean;
  executeSearch: () => Promise<BranchLookupCard[]>;
};

const buildRequestBody = (values: BranchLookupValues, defaultEhiDatabase: string): BranchDetailsSearchCriteria => {
  const mappedBrands = values[BranchLookupFields.Brands] as Brand[];
  const mappedLocationTypes = values[BranchLookupFields.LocationTypes]?.map((location) => location.code);

  const searchRequestObject: BranchDetailsSearchCriteria = {
    country: values[BranchLookupFields.Country],
    paginationCriteria: {
      pageSize: MAX_PAGE_SIZE,
    },
    branchPhase: BranchPhase.OPEN,
    airport: getAirportType(mappedLocationTypes),
    branchTypes: getBranchTypes(mappedLocationTypes),
    businessIndicators:
      mappedLocationTypes?.length !== Object.keys(DisplayedLocationTypes).length
        ? getBusinessIndicators(mappedLocationTypes, defaultEhiDatabase)
        : undefined,
    brand: mappedBrands,
  };

  const searchType = values[BranchLookupFields.SearchBy];
  const searchInput = values[BranchLookupFields.SearchInputValue];

  if (searchType === SearchByType.Keyword) {
    searchRequestObject.tag = {
      searchText: searchInput.toUpperCase(),
      matchType: MatchType.FUZZY,
    };
  }

  if (searchType === SearchByType.PostalCode) {
    searchRequestObject.geographical = {
      distanceMeasurementMethod: DistanceMeasurementMethod.DRIVING_DISTANCE,
      postalCode: searchInput,
      searchRadius: {
        distance: parseInt(values[BranchLookupFields.Distance], 10),
        unit: DistanceUnitOfMeasure.MILES,
      },
      countryCode: values[BranchLookupFields.Country],
    };
  }

  return searchRequestObject;
};

export const useFetchBranches = (values: BranchLookupValues): UseBranchSearchReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showAlert } = useAlert();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';

  const handleUnexpectedError = useCallback((error: unknown) => {
    logError({
      error: {
        message: 'Unexpected error occurred during branch search',
        supportInformation: {
          serviceError: error,
        },
      },
      severity: ErrorSeverity.Fatal,
    });
  }, []);

  const executeSearch = useCallback(async () => {
    setIsLoading(true);
    try {
      if (values[BranchLookupFields.SearchBy] === SearchByType.PostalCode) {
        // The Location API has a cap of 25 results for Geographical Searches.
        // This will make to two calls if the initial Geo Search has over 25 results
        return await Promise.all<BranchLookupCard[]>(
          await searchBranches(buildRequestBody(values, defaultEhiDatabase)).then(
            async (value: BranchSearchResults) => {
              if (value.pagination?.nextCursor && value.branches && value.branches?.length < MAX_PAGE_SIZE) {
                const response = await searchBranches({
                  ...buildRequestBody(values, defaultEhiDatabase),
                  paginationCriteria: {
                    pageSize: MAX_PAGE_SIZE,
                    nextCursor: value.pagination.nextCursor,
                  },
                });
                return [...transformBranchLookupData(value.branches), ...transformBranchLookupData(response.branches)];
              } else {
                return transformBranchLookupData(value.branches);
              }
            }
          )
        );
      } else {
        const response = await searchBranches(buildRequestBody(values, defaultEhiDatabase));
        return transformBranchLookupData(response.branches);
      }
    } catch (err) {
      const errorMessage = (err as EhiErrors)?.errors?.[0]?.localizedMessage;
      if (errorMessage) {
        await showAlert({
          title: 'Error',
          description: errorMessage,
        });
      } else {
        handleUnexpectedError(err);
      }
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [values, showAlert, handleUnexpectedError, defaultEhiDatabase]);

  return {
    isLoading,
    executeSearch,
  };
};
