import { FC, useMemo } from 'react';
import { EhiButton, ehiTheme } from '@ehi/ui';
import { Box } from '@mui/material';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { CountrySearchDropDown } from 'components/shared/forms/CountrySearchDropDown';
import { GroupDropDown } from 'components/shared/forms/GroupDropDown';
import { MaxLengthTextField } from 'components/shared/forms/MaxLengthTextField';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { piiField } from '@ehi/support';
import { useTranslations } from 'components/shared/i18n';
import { FormProvider, useForm } from 'react-hook-form';
import { PrimaryButton } from 'components/shared/ui/styles/Global.styles';
import { useLocationGroups, useLocationQuery } from 'services/location/locationQueries';
import { loadEhiLocationCookie } from '@ehi/location';
import {
  accountSearchValidationSchema,
  getAccountSearchValuesInitialValues,
} from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/accountSearchUtils';
import { DAILY_RENTAL, getGroupOptions } from 'utils/branchLookupUtils';
import { AccountSearchFields, AccountSearchValues } from './AccountSearchTypes';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { getDefaultCountry } from 'utils/locationUtils';
import { AccountTypesDropDown } from './AccountTypesDropDown';

export const AccountSearch: FC = (): JSX.Element => {
  const { t } = useTranslations();
  const cookie = loadEhiLocationCookie();

  const { data: locationData, isFetching: isLocationFetching } = useLocationQuery(cookie?.peoplesoftId ?? '');
  const defaultCountry = useMemo(() => getDefaultCountry(locationData), [locationData]);

  const { data: groups, isFetching: isGroupsFetching } = useLocationGroups(defaultCountry, DAILY_RENTAL);
  const groupOptions = useMemo(() => getGroupOptions(groups), [groups]);

  const resolver = useYupValidationResolver(accountSearchValidationSchema(t));
  const initialValues = useMemo(
    () => getAccountSearchValuesInitialValues(defaultCountry, groupOptions),
    [defaultCountry, groupOptions]
  );
  const formMethods = useForm({
    resolver,
    defaultValues: initialValues,
  });

  const [countrySelect, accountName, phoneNumber] = formMethods.watch([
    AccountSearchFields.Country,
    AccountSearchFields.AccountName,
    AccountSearchFields.PhoneNumber,
  ]);

  const onSubmit = async (values: AccountSearchValues) => {
    // Implemented in search functionality
    console.log('submit', values);
  };

  return (
    <FormProvider {...formMethods}>
      <Box padding={ehiTheme.spacing(2)} style={{ background: '#f5f5f5' }}>
        <GridContainer>
          <>
            <GridItem sm={6}>
              <CountrySearchDropDown defaultCountry={defaultCountry} name={AccountSearchFields.Country} required />
            </GridItem>
            <GridItem sm={6}>
              <GroupDropDown
                filedName={AccountSearchFields.Groups}
                countrySelect={countrySelect}
                bgColor='#f9f9f9'
                required
              />
            </GridItem>
          </>
          <>
            <GridItem sm={6}>
              <MaxLengthTextField
                name={AccountSearchFields.AccountName}
                autoFocus
                type='text'
                label={t('rateAndBilling.accountName')}
                maxLength={60}
              />
            </GridItem>
            <GridItem sm={6}>
              <AccountTypesDropDown accountTypeValues={initialValues[AccountSearchFields.AccountTypes]} />
            </GridItem>
          </>
          <GridItem sm={6}>
            <PhoneTextField
              country={countrySelect}
              name={AccountSearchFields.PhoneNumber}
              label={t('rateAndBilling.accountPhone')}
              data-testid='phone-number-field'
              className={piiField}
            />
          </GridItem>
        </GridContainer>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <EhiButton type={'reset'} onClick={() => formMethods.reset()}>
            {t('common.reset')}
          </EhiButton>
          <PrimaryButton
            data-testid='searchAccount'
            disabled={
              (!phoneNumber && (accountName as string)?.length < 3) ||
              (!accountName && (phoneNumber as string)?.length < 4)
            }
            onClick={formMethods.handleSubmit(onSubmit)}>
            {t('common.search')}
          </PrimaryButton>
        </Box>
        <ProgressOverlay inProgress={isLocationFetching || isGroupsFetching} />
      </Box>
    </FormProvider>
  );
};
