import { FC, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { SelectField } from 'components/shared/forms/SelectField';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { StyledDivider } from 'components/quickRes/QuickRes.styles';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useFormContext, useWatch } from 'react-hook-form';
import { updateContactInformation } from 'services/booking/bookingService';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { QuickResContactCountryCode } from './QuickResContactCountryCode';
import { loadEhiLocationCookie } from '@ehi/location';
import { useLocationQuery } from 'services/location/locationQueries';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { EhiErrors } from 'services/types/EhiErrorsTypes';

const QuickResContact: FC = () => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { data: phoneTypeDomain } = usePhoneTypesQuery();
  const cookieLocation = loadEhiLocationCookie();
  const { data: locationQuery } = useLocationQuery(cookieLocation?.peoplesoftId ?? '');
  const [isContactUpdating, setIsContactUpdating] = useState<boolean>(false);

  const contactTypes = useMemo(() => {
    return phoneTypeDomain?.map((phone) => ({ label: phone.name || '', value: phone.code || '' })) || [];
  }, [phoneTypeDomain]);
  const {
    getFieldState,
    trigger,
    setError,
    formState: { errors },
  } = useFormContext();
  const [countryCode, phone] = useWatch({
    name: [QuickResFields.CountryCode, QuickResFields.Phone],
  });

  const handleOnBlur = async (field: string) => {
    await trigger(field);

    if (getFieldState(field).isDirty && !errors[QuickResFields.Phone]) {
      try {
        setIsContactUpdating(true);
        const payload = {
          phone: { number: phone },
        };

        const result = await updateAndRefresh(() => updateContactInformation(bookingEditorId, payload));
        if (result?.errors?.length) {
          setError(field, { message: result?.errors?.[0]?.localizedMessage });
        }
      } catch (error) {
        setError(field, { message: (error as EhiErrors)?.errors?.[0]?.localizedMessage });
      } finally {
        setIsContactUpdating(false);
      }
    }
  };

  return (
    <GridContainer>
      <GridItem sm={12}>
        <h3 data-testid={'res-contact'}>{t('driver.contact')}</h3>
      </GridItem>
      <GridItem sm={4}>
        <SelectField
          name={QuickResFields.ContactType}
          label={t('driver.type')}
          data-testid={QuickResFields.ContactType}
          options={contactTypes || []}
          hasNoneOption={false}
        />
      </GridItem>
      <GridItem sm={4}>
        <QuickResContactCountryCode name={QuickResFields.CountryCode} />
      </GridItem>
      <GridItem sm={4}>
        <PhoneTextField
          country={countryCode ? countryCode.key : locationQuery?.location?.countryCode}
          name={QuickResFields.Phone}
          label={t('driver.phone')}
          data-testid={QuickResFields.Phone}
          onBlur={async () => {
            await handleOnBlur(QuickResFields.Phone);
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12}>
        <StyledDivider />
      </GridItem>
      <ProgressOverlay inProgress={isContactUpdating} />
    </GridContainer>
  );
};
export default QuickResContact;
