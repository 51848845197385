import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { EhiButton, H6 } from '@ehi/ui';
import {
  RateAndBillingLabel,
  RateAndBillingSectionBox,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';

export const BillToSection = () => {
  const { t } = useTranslations();
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);

  return (
    <RateAndBillingSectionBox data-testid='billToSection'>
      <Box display={'flex'} justifyContent={'space-between'}>
        <H6>{t('rateAndBilling.billTo')}</H6>
        {!isReadOnly && <EhiButton>{t('common.edit')}</EhiButton>}
      </Box>
      <Grid container>
        <Grid item xs={6} sm={6} data-testid='accountNumber'>
          <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.accountNumber')}</RateAndBillingLabel>
        </Grid>
        <Grid item xs={6} sm={6} data-testid='billingNumber'>
          <RateAndBillingLabel data-testid='label'>{t('rateAndBilling.billingNumber')}</RateAndBillingLabel>
        </Grid>
      </Grid>
    </RateAndBillingSectionBox>
  );
};
