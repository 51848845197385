import { FC, useCallback, useState } from 'react';
import { RateSourceInfoBox, RateSourceLink } from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import InfoIcon from '@mui/icons-material/Info';
import { Body2, EhiButton, ehiTheme, H6 } from '@ehi/ui';
import { Grid, RadioGroup } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { useTranslations } from 'components/shared/i18n';
import { RateSource } from 'services/booking/bookingTypes';
import { modifyRateSource } from 'services/booking/bookingService';
import { logError } from 'components/shared/logger/splunkLogger';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { ErrorSeverity } from '@ehi/analytics';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { loadCounterCookie, loadEhiLocationCookie } from '@ehi/location';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useAppSelector } from 'redux/hooks';
import { RateQualificationsDialog } from 'components/flexFlow/rateAndBilling/section/rateSource/rateQualificationsDialog/RateQualificationsDialog';
import {
  selectBookingEditorId,
  selectGeneralConditions,
  selectIsReadOnlyFlow,
  selectRateSource,
} from 'redux/selectors/bookingEditor';

type RateSourceInfoProps = {
  accountName?: string;
  accountType?: string;
};

export const RateSourceInfo: FC<RateSourceInfoProps> = ({ accountName, accountType }): JSX.Element => {
  const { t } = useTranslations();
  const cookieLocation = loadEhiLocationCookie();
  const counterCookie = loadCounterCookie();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const billingCycleList = [t('rateAndBilling.calendarDay'), t('rateAndBilling.24Hour')];
  const radioButtonsDisabled = true;
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const generalConditions = useAppSelector(selectGeneralConditions);

  const [loading, setLoading] = useState(false);
  const [billingCycleSelection, setBillingCycleSelection] = useState<string>(t('rateAndBilling.24Hour'));
  const [showRateQualificationsDialog, setShowRateQualificationsDialog] = useState(false);

  const handleContractualConditions = useCallback(async () => {
    const conditions = () => generalConditions?.map((item) => item.text).join(' ') as string;
    await showAlert({
      title: t('rateAndBilling.contractualConditions'),
      description: conditions(),
    });
  }, [generalConditions, showAlert, t]);

  const handleRemovingRateSource = useCallback(async () => {
    try {
      setLoading(true);
      const rateSourceRequestBody: RateSource = {
        type: 'RETAIL',
      };
      const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
      if (errors) {
        await showAlert({
          title: t('error.error'),
          description: `${t('rateAndBilling.unableToRemove')}: ${errors?.[0]?.localizedMessage || ''}`,
        });
      }
    } catch (error) {
      logError({
        error: {
          message: t('rateAndBilling.unableToRemove'),
          supportInformation: {
            transactionType: TransactionTypes.CreateFullRes,
            location: cookieLocation,
            counter: counterCookie?.counterId,
            serviceError: error,
          },
        },
        severity: ErrorSeverity.Fatal,
      });
    } finally {
      setLoading(false);
    }
  }, [bookingEditorId, cookieLocation, counterCookie?.counterId, showAlert, t, updateAndRefresh]);

  return (
    <RateSourceInfoBox data-testid='rateSourceInfo'>
      {(accountName || accountType) && (
        <div style={{ display: 'flex', textAlign: 'center', paddingBottom: ehiTheme.spacing(2) }}>
          <Grid container spacing={2} display={'flex'} alignItems={'center'}>
            {accountName && (
              <Grid item data-testid='accountName'>
                <H6>{accountName}</H6>
              </Grid>
            )}
            {accountType && (
              <Grid item data-testid='accountType'>
                <Body2>{accountType}</Body2>
              </Grid>
            )}
          </Grid>
          {!isReadOnly && (
            <EhiButton variant={'text'} color={'error'} size={'small'} onClick={handleRemovingRateSource}>
              {t('common.remove')}
            </EhiButton>
          )}
        </div>
      )}
      <RateSourceLink
        disabled={!rateSource?.rateQualifications}
        data-testid='rateQualificationLink'
        onClick={() => setShowRateQualificationsDialog(true)}>
        <InfoIcon sx={{ paddingRight: ehiTheme.spacing(1) }} />
        <Body2 bold>{t('rateAndBilling.rateQualifications.rateQualifications')}</Body2>
      </RateSourceLink>
      <RadioGroup data-testid='rateSourceRadioGroup' style={{ paddingTop: ehiTheme.spacing(1) }}>
        <Caption2 style={{ paddingBottom: ehiTheme.spacing(1) }}>{t('rateAndBilling.billingCycle')}</Caption2>
        {billingCycleList.map((value: string) => (
          <FormControlLabel
            key={value}
            value={value}
            checked={billingCycleSelection === value}
            disabled={radioButtonsDisabled}
            data-testid={`${value}-radio`}
            onClick={() => {
              !radioButtonsDisabled && setBillingCycleSelection(value);
            }}
            control={<Radio color='primary' />}
            label={value}
            sx={{
              '& .MuiRadio-root': {
                paddingTop: ehiTheme.spacing(0.5),
                paddingBottom: ehiTheme.spacing(0.5),
              },
              '& .MuiSvgIcon-root': {
                fontSize: 24,
              },
            }}
          />
        ))}
      </RadioGroup>
      <EhiDivider style={{ margin: ehiTheme.spacing(2, 0) }} />
      <RateSourceLink
        data-testid='contractualConditionsLink'
        disabled={!generalConditions}
        onClick={!generalConditions ? undefined : handleContractualConditions}>
        <InfoIcon sx={{ paddingRight: ehiTheme.spacing(1) }} />
        <Body2 bold>{t('rateAndBilling.contractualConditions')}</Body2>
      </RateSourceLink>
      <ProgressOverlay inProgress={loading} />
      <RateQualificationsDialog
        open={showRateQualificationsDialog}
        rateQualifications={rateSource?.rateQualifications}
        closeModal={() => setShowRateQualificationsDialog(false)}
      />
    </RateSourceInfoBox>
  );
};
