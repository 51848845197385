import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslations } from 'components/shared/i18n';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';
import { EMPTY_VALUE } from 'utils/constants';

export type SnackbarProps = {
  message: string;
  onViewReservation?: () => void;
  isOpen: boolean;
};
const autoHideDuration = 6000;

export default function ReservationSnackBar({ message, onViewReservation, isOpen }: SnackbarProps) {
  const { setSnackBarRes } = useResSnackbarContext();
  const { t } = useTranslations();

  const handleClose = () => {
    setSnackBarRes({ message: EMPTY_VALUE, isOpen: false });
  };
  const action = (
    <>
      <Button data-testid={'viewButton'} color='secondary' size='small' onClick={onViewReservation}>
        {t('common.view')}
      </Button>

      <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        data-testid={'snackBar'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
}
