import { FC, ReactNode, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { IconButton, ListItemIcon, ListItemText, ListSubheader, MenuItem, TextField } from '@mui/material';
import { Option } from 'components/shared/ui/OptionTypes';
import { useTranslations } from 'components/shared/i18n';
import { useCountriesQuery } from 'services/location/locationQueries';
import { generateAllCountryMenuItemsWithCorporate } from 'utils/formUtils';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { CORPORATE_COUNTRIES } from 'utils/countryUtils';
import { EhiButton, ehiTheme } from '@ehi/ui';
import { useFormContext } from 'react-hook-form';
import {
  CountryDropDownCaption,
  CountryDropDownFooter,
  CountryDropDownFooterItem,
  CountryDropDownHeader,
  CountryDropDownHeaderItem,
  StyledFormTextField,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { Check, Close, Search } from '@mui/icons-material';
import { EMPTY_VALUE } from 'utils/constants';

type CountrySearchDropDownProps = {
  defaultCountry: string;
  name: string;
  required?: boolean;
};

export const CountrySearchDropDown: FC<CountrySearchDropDownProps> = ({ defaultCountry, name, required }) => {
  const { t } = useTranslations();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { resetField, watch } = useFormContext();
  const countrySelect = watch(name);
  const { data } = useCountriesQuery();
  const options = useMemo<Option[]>(() => {
    if (!data?.length) return [];
    return generateAllCountryMenuItemsWithCorporate(data);
  }, [data]);

  const getCountryOptions = useCallback(
    (options: Option[], isCorporateCountryOptions: boolean) => {
      return options
        .filter(
          (value) =>
            (searchValue.length === 0 || value.label.toLowerCase().includes(searchValue.toLowerCase())) &&
            (isCorporateCountryOptions
              ? CORPORATE_COUNTRIES.includes(value.value)
              : !CORPORATE_COUNTRIES.includes(value.value))
        )
        .map((option) => {
          return (
            <MenuItem
              disableRipple
              key={option.value}
              value={option.value}
              style={{ padding: ehiTheme.spacing(2) }}
              data-testid={`country-${option.value}`}>
              <ListItemText
                primary={
                  option.value === defaultCountry ? `${t(option.label)} (${t('common.default')})` : t(option.label)
                }
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
              />
              {countrySelect === option.value && (
                <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                  <Check color={'action'} />
                </ListItemIcon>
              )}
            </MenuItem>
          );
        });
    },
    [countrySelect, defaultCountry, searchValue, t]
  );

  const corporateCountries = getCountryOptions(options, true);
  const otherCountries = getCountryOptions(options, false);

  const handleClose = () => {
    searchValue.length > 0 && setSearchValue('');
    setOpen(false);
  };

  const handleOpen = (event: SyntheticEvent<Element, Event>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    setOpen(true);
  };

  const handleReset = () => {
    setSearchValue('');
    resetField(name);
  };

  const handleKeyDown = (e: any, isReset = false) => {
    if (e.key !== 'Escape' || isReset) {
      // Prevents autoselecting item while typing (default Select behaviour)
      e.stopPropagation();
    }
  };

  const handleRenderingValue = useCallback(
    (value: unknown) => {
      return (
        options?.find((option) => (value === EMPTY_VALUE ? option?.value === countrySelect : option?.value === value))
          ?.label ?? EMPTY_VALUE
      );
    },
    [countrySelect, options]
  );

  const SearchIcon = (): ReactNode => {
    return <Search color={'secondary'} style={{ paddingRight: ehiTheme.spacing(1) }} />;
  };

  return (
    <StyledFormTextField
      name={name}
      data-testid={name}
      label={required ? t('location.country*') : t('location.country')}
      select
      SelectProps={{
        open: open,
        displayEmpty: true,
        value:
          (searchValue.length > 0 && !countrySelect?.includes(searchValue)) || !countrySelect
            ? EMPTY_VALUE
            : countrySelect,
        renderValue: handleRenderingValue,
        MenuProps: {
          anchorEl: anchorEl,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '&& .Mui-selected': { backgroundColor: '#ffffff' },
            transition: 'none !important',
          },
          slotProps: {
            paper: {
              style: {
                width: anchorEl ? `${anchorEl.clientWidth * 1.25}px` : 'auto',
                maxHeight: window.innerHeight / 1.75,
                ...(window.innerWidth <= 800 && {
                  minWidth: '25%',
                }),
              },
            },
            root: { sx: { '.MuiList-root': { padding: 0 } } },
          },
        },
        onClose: handleClose,
        onOpen: handleOpen,
      }}>
      <ListSubheader>
        <CountryDropDownHeader data-testid={'country-drop-down-header'}>
          <CountryDropDownHeaderItem>
            <h3>{t('location.country')}</h3>
            <IconButton onClick={handleClose} edge='end'>
              <Close color={'secondary'} data-testid='country-close-dropdown' />
            </IconButton>
          </CountryDropDownHeaderItem>
          <CountryDropDownHeaderItem
            style={{
              paddingBottom: ehiTheme.spacing(3),
            }}>
            <TextField
              value={searchValue}
              placeholder={t('location.searchForCountry')}
              data-testid={'search-country-field'}
              fullWidth
              type={'text'}
              InputProps={{
                startAdornment: SearchIcon(),
              }}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </CountryDropDownHeaderItem>
        </CountryDropDownHeader>
      </ListSubheader>
      {corporateCountries.length > 0 && (
        <CountryDropDownCaption>{t('location.corporateCountries')}</CountryDropDownCaption>
      )}
      {corporateCountries}
      <EhiDivider />
      {otherCountries.length > 0 && <CountryDropDownCaption>{t('location.otherCountries')}</CountryDropDownCaption>}
      {otherCountries}
      <CountryDropDownFooter data-testid={'country-drop-down-footer'}>
        <EhiDivider />
        <CountryDropDownFooterItem>
          <EhiButton
            data-testid={'branchLookupCountryReset'}
            onClick={handleReset}
            onKeyDown={(e) => handleKeyDown(e, true)}>
            {t('common.reset')}
          </EhiButton>
        </CountryDropDownFooterItem>
      </CountryDropDownFooter>
    </StyledFormTextField>
  );
};
