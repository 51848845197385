import {
  AccountSearchFields,
  AccountSearchValues,
  GroupOptionType,
} from 'components/flexFlow/rateAndBilling/section/rateSource/rateSourceDialog/AccountSearchTypes';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { EMPTY_VALUE } from 'utils/constants';

export const enum accountTypes {
  Association_Club = 'ASSOCIATION/CLUB',
  Body_Shop = 'BODY_SHOP',
  Business_Rental_Commercial = 'BUSINESS_RENTAL/COMMERCIAL',
  Convention = 'CONVENTION',
  Dealership = 'DEALERSHIP',
  Government = 'GOVERNMENT',
  Insurance = 'INSURANCE',
  Other = 'OTHER',
  Promotion = 'PROMOTION',
  Tour_Operator = 'TOUR_OPERATOR',
  Travel_Agent = 'TRAVEL_AGENT',
}
export type accountTypeOptionType = {
  name: string;
  code: string;
};

export const getAccountTypeOptions = (t: TFunction<'translation'>): accountTypeOptionType[] => {
  return [
    { name: t('rateAndBilling.associationClub'), code: accountTypes.Association_Club },
    { name: t('rateAndBilling.bodyShop'), code: accountTypes.Body_Shop },
    { name: t('rateAndBilling.businessRentalCommercial'), code: accountTypes.Business_Rental_Commercial },
    { name: t('rateAndBilling.convention'), code: accountTypes.Convention },
    { name: t('rateAndBilling.dealership'), code: accountTypes.Dealership },
    { name: t('rateAndBilling.government'), code: accountTypes.Government },
    { name: t('rateAndBilling.insurance'), code: accountTypes.Insurance },
    { name: t('rateAndBilling.other'), code: accountTypes.Other },
    { name: t('rateAndBilling.promotion'), code: accountTypes.Promotion },
    { name: t('rateAndBilling.tourOperator'), code: accountTypes.Tour_Operator },
    { name: t('rateAndBilling.travelAgent'), code: accountTypes.Travel_Agent },
  ];
};

export const getAccountTypeValues = (): string[] => {
  return [
    accountTypes.Association_Club,
    accountTypes.Body_Shop,
    accountTypes.Business_Rental_Commercial,
    accountTypes.Convention,
    accountTypes.Dealership,
    accountTypes.Government,
    accountTypes.Insurance,
    accountTypes.Other,
    accountTypes.Promotion,
    accountTypes.Tour_Operator,
    accountTypes.Travel_Agent,
  ];
};

export const getAccountSearchValuesInitialValues = (
  defaultCountry: string,
  groupOptions: GroupOptionType[] | []
): AccountSearchValues => {
  return {
    [AccountSearchFields.Country]: defaultCountry,
    [AccountSearchFields.Groups]: groupOptions,
    [AccountSearchFields.AccountName]: EMPTY_VALUE,
    [AccountSearchFields.AccountTypes]: getAccountTypeValues(),
    [AccountSearchFields.PhoneNumber]: EMPTY_VALUE,
  };
};

export const accountSearchValidationSchema = (t: TFunction<'translation'>) =>
  Yup.object().shape({
    [AccountSearchFields.AccountName]: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .min(3, t('validation.minFieldLength', { fieldLength: 3 })),
    [AccountSearchFields.PhoneNumber]: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .min(4, t('validation.minFieldLength', { fieldLength: 4 })),
  });
