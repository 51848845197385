import { Card, Grid } from '@mui/material';
import { BrandLogo } from 'components/shared/ui/logos/BrandLogo';
import { Brand } from 'utils/constants';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { parseUrn } from 'utils/urnUtils';
import { HeaderName, HeaderValue, RentalHeaderH6, StyledPaper } from 'components/customerHeader/CustomerHeader.styles';
import { useAppSelector } from 'redux/hooks';
import {
  selectBrand,
  selectCharges,
  selectPickup,
  selectReservationData,
  selectReturn,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { FunctionComponent } from 'react';
import { useVehicleContentQuery } from 'services/vehicleContent/vehicleContentQueries';
import { getDaysDiff } from 'utils/dateUtils';
import { LoyaltyProgramLogo } from 'components/shared/ui/logos/LoyaltyProgramLogo';
import { formatCurrency } from 'utils/currencyUtils';
import { getCarClassDescription } from 'utils/vehicleUtils';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { StyledWarningIcon } from 'components/shared/ui/styles/WarningIcon.styles';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';
import { Body2 } from '@ehi/ui';

const CustomerHeader: FunctionComponent = () => {
  const { t } = useTranslations();

  const { driverData } = useGetRenterProfile();
  const { rateSourceInfo } = useAccountDetails();
  const { data: vehicleContent } = useVehicleContentQuery();
  const vehicleClassSelection = useAppSelector(selectVehicleClassSelection);
  const reservation = useAppSelector(selectReservationData);
  const brandId = useAppSelector(selectBrand);
  const charges = useAppSelector(selectCharges);
  const parsedBrand = parseUrn(brandId) as Brand;
  const vehicleClass = vehicleClassSelection?.preferred
    ? getCarClassDescription(parseUrn(vehicleClassSelection?.preferred), vehicleContent)
    : t('vehicle.noVehicleSelected');
  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);
  const lengthOfRental = getDaysDiff(pickupData?.dateTime, returnData?.dateTime);
  const { locale } = useLocale();
  const currencyCode = reservation && charges?.totalCharges?.currencyCode ? charges.totalCharges.currencyCode : 'USD';
  const estTotal = reservation && charges?.totalCharges?.amount ? charges.totalCharges.amount.toString() : '';
  const formattedCurrency = estTotal ? formatCurrency(parseFloat(estTotal), currencyCode, locale) : '--';

  return (
    <StyledPaper data-testid={'customer-header'}>
      <Card>
        <Grid container direction='row' alignItems={'center'}>
          <Grid container item xs={6} alignItems={'center'} spacing={1}>
            <Grid item>
              <BrandLogo brand={parsedBrand} height={40} width={40} />
            </Grid>
            <Grid item>
              <div data-testid={'renter-name'}>
                {/* Note: get driver profile doesn't return returnWarningFlag, so look for renterWarning object */}
                {driverData?.renterWarning && <StyledWarningIcon data-testid='customerHeaderWarning' />}
                {driverData?.lastName && (
                  <RentalHeaderH6 bold display={'inline'}>
                    {driverData.lastName}
                  </RentalHeaderH6>
                )}
                {driverData?.firstName && <RentalHeaderH6 display='inline'>, {driverData.firstName}</RentalHeaderH6>}
                {!driverData?.firstName && !driverData?.lastName && <RentalHeaderH6>--</RentalHeaderH6>}
                {(rateSourceInfo?.name || rateSourceInfo?.type) && (
                  <Body2>
                    {rateSourceInfo.type && <b>{`${rateSourceInfo.type}, `}</b>}
                    {rateSourceInfo.name ?? ''}
                  </Body2>
                )}
              </div>
              {driverData?.loyaltyType && (
                <LoyaltyProgramLogo membershipProgram={driverData?.loyaltyType} height={24} width={64} />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent={'flex-end'}
            sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' } }}
            spacing={3}>
            <Grid item>
              <HeaderName data-testid={'lengthOfRental'} sx={{ color: 'black', textAlign: 'right' }}>
                {pickupData?.dateTime && returnData?.dateTime
                  ? `${lengthOfRental}${lengthOfRental === 1 ? t('whenWhere.day') : t('whenWhere.days')}`
                  : '--'}
              </HeaderName>
              <HeaderValue data-testid={'vehicleClass'} sx={{ whiteSpace: 'nowrap' }}>
                {vehicleClass}
              </HeaderValue>
            </Grid>
            <Grid item sx={{ textAlign: 'right' }} data-testid={'estimatedCharges'}>
              <HeaderName sx={{ color: 'black', textAlign: 'r' }} data-testid='estimatedChargesValue'>
                {formattedCurrency}
              </HeaderName>
              <HeaderValue sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
                {t('common.estimatedCharges')}
              </HeaderValue>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </StyledPaper>
  );
};
export default CustomerHeader;
